import React from 'react';
import { graphql } from 'gatsby';
import Carousel from 'react-multi-carousel';
import type { Beer } from '../types';
import Layout from '../components/layout/layout';
import OtherBeers from '../components/beers/others';
import { H1, H2, P, Image, ButtonLink } from '../components';
import { useBreakpoints } from '../hooks';
import {
  getBorderColorClassForBeer,
  getTextColorClassForBeer,
  getBgColorClassForBeer,
  getBrewingImgPathForBeer,
  getPouringImgPathForBeer,
  getFoodPairingImgPathForBeer,
  getWheelOneImgPathForBeer,
  getWheelTwoImgPathForBeer,
} from '../utils';

interface BeerProps {
  data: {
    beer: Beer;
  };
}

const Divider = <hr className="border-t-4 border-primary w-full" />;

const BeerDetails: React.FC<BeerProps> = ({ data: { beer } }) => {
  const breakpoints = useBreakpoints();

  return (
    <Layout title={`${beer.name} | Beers`}>
      <div className="w-full pt-32 md:pt-40 lg:pt-44">
        <div className="px-10 w-full flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-20">
          <div className="w-full lg:max-w-2xl lg:w-1/3 m-auto lg:m-0">
            <Carousel responsive={{ uniform: { breakpoint: { min: 0, max: 4000 }, items: 1 } }} showDots={true} ssr>
              <Image src={beer.bottleImg} alt={`Bottle of ${beer.name} beer`} className="w-full max-h-screen m-auto" />
              <div className="w-full h-full flex justify-center items-center">
                <Image
                  src={beer.pouringImg}
                  alt={`Pouring a bottle of ${beer.name} beer into a lokabrews beer glass`}
                  className="w-full"
                />
              </div>
            </Carousel>
          </div>
          <div className="mobile:w-full flex flex-col items-center justify-center gap-6 text-center">
            <Image src={beer.titleImg} alt={beer.name} className="w-32 lg:w-40 xl:w-48" />
            {Divider}
            <P className={`text-xl override:m-0 ${getTextColorClassForBeer(beer)}`}>
              {beer.description}
              <br />
              {beer.alc}
            </P>
            <div className="w-full flex flex-col items-center justify-center text-center">
              {Divider}
              <div className="w-full flex mobile:flex-col flex-row gap-5 text-center">
                <div className="w-full flex flex-col justify-center items-center p-5">
                  <P className={`text-xl override:m-0 ${getTextColorClassForBeer(beer)}`}>BITTERNESS: {beer.ibu}</P>
                  <Image
                    src={beer.ibuImg}
                    alt={`${beer.name} has a bitterness level of ${beer.ibu}`}
                    className="w-full max-w-sm"
                  />
                </div>
                <div className="hidden md:block border-l-4 border-primary h-48"></div>
                <div className="w-full flex flex-col justify-center items-center p-5">
                  <P className={`text-xl override:m-0 ${getTextColorClassForBeer(beer)}`}>COLOUR: {beer.color}</P>
                  <Image
                    src={beer.colorImg}
                    alt={`${beer.name} has a color level of ${beer.color}`}
                    className="w-full max-w-sm"
                  />
                </div>
              </div>
              {Divider}
            </div>
            <div className="w-full flex flex-col items-center justify-center text-center">
              <H2 className="text-center">Ingredients</H2>
              <div className="flex justify-center items-center w-full gap-1">
                {beer.ingredients.map((ingredient) => (
                  <Image
                    key={`${beer.name}-${ingredient.alt}`}
                    src={ingredient.img}
                    alt={ingredient.alt}
                    className="w-24"
                  />
                ))}
              </div>
            </div>
            {Divider}
            <div className="w-full flex flex-col gap-2 justify-center items-center">
              <Image src="beers/foodpair.png" alt="Cooking pot" className="w-10 lg:w-16" />
              <P className={`text-xl override:m-0 ${getTextColorClassForBeer(beer)}`}>{beer.pairingTeaser}</P>
            </div>
            {Divider}
            <div className="w-full flex flex-col items-center justify-center text-center">
              <H2 className="text-center">Awards</H2>
              <div className="flex justify-center items-center w-full gap-1">
                {beer.awards.map((award) => (
                  <Image key={`${beer.name}-${award.name}`} src={award.img} alt={award.name} className="w-28" />
                ))}
              </div>
            </div>
            {Divider}
            <ButtonLink
              overrideBtnClassName={`${getBorderColorClassForBeer(beer, 'override:')} ${getTextColorClassForBeer(
                beer,
                'override:',
              )}`}
              to={`/contact`}
              main
            >
              Order now
            </ButtonLink>
          </div>
        </div>
        <div
          className={`relative w-full flex flex-col lg:flex-row justify-center items-center gap-10 min-h-screen mt-20 py-20 lg:mt-40 lg:py-40 ${getBgColorClassForBeer(
            beer,
          )}`}
        >
          <Image
            src="stamp.png"
            alt="Authentic Lokabrews stamp"
            className="absolute left-0 top-0 ml-10 -mt-10 w-20 lg:w-40 lg:-mt-20"
          />
          <Image src={beer.artImg} alt="Bottle Paintings" className="w-full lg:w-1/3" />
          <div className="flex flex-col justify-center items-center text-center gap-10">
            <H1 className="m-0 text-4xl lg:text-6xl xl:text-8xl">Loka {beer.name}</H1>
            <h2 className="uppercase text-primary text-2xl font-semibold">{beer.sub}</h2>
            {beer.introduction.map((paragraph, index) => (
              <P key={index} className="override:text-primary override:font-semibold m-0">
                {paragraph}
              </P>
            ))}
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center gap-10 py-10 lg:py-20 mt-20 lg:mt-40 text-center">
          <H1 asH2 className="m-0 text-4xl lg:text-6xl xl:text-8xl">
            BREWING PROCESS
          </H1>
          <h2 className={`uppercase text-2xl font-semibold ${getTextColorClassForBeer(beer)}`}>
            A TRADITIONAL BELGIAN APPROACH
          </h2>
          <Image
            src={getBrewingImgPathForBeer(beer, breakpoints.isMd || breakpoints.isSm)}
            alt={`Brewing process of ${beer.name} beer`}
            className="p-10 w-full"
          />
        </div>
        <div
          className={`w-full flex flex-col justify-center items-center pt-10 gap-10 text-center ${getBgColorClassForBeer(
            beer,
            undefined,
            true,
          )}`}
        >
          <H1 asH2 className="m-0 text-4xl lg:text-6xl xl:text-8xl">
            POURING GUIDE
          </H1>
          <h2 className={`uppercase text-2xl font-semibold ${getTextColorClassForBeer(beer)}`}>
            A TRADITIONAL BELGIAN APPROACH
          </h2>
          <Image
            src={getPouringImgPathForBeer(beer, breakpoints.isMd || breakpoints.isSm)}
            alt={`Pouring guide for ${beer.name} beer`}
            className="p-10 w-full"
          />
          <P className={getTextColorClassForBeer(beer, 'override:')}>
            A beer that is beautifully poured from the bottle is a work of art, pleasing to the eye and a caress for the
            senses.
          </P>
        </div>
        <div
          className={`w-full text-center flex flex-col justify-center items-center gap-10 min-h-screen py-20 lg:py-40 ${getBgColorClassForBeer(
            beer,
            undefined,
            true,
          )}`}
        >
          <H1 asH2 className="m-0 text-4xl lg:text-6xl xl:text-8xl">
            Analytical Profile
          </H1>
          <P className={getTextColorClassForBeer(beer, 'override:')}>{beer.analyticalDescription}</P>
          <Image
            src={getWheelOneImgPathForBeer(beer)}
            alt={`Analytical profile of ${beer.name} beer`}
            className="w-full max-w-4xl"
          />
        </div>
        <div
          className={`w-full text-center flex flex-col justify-center items-center gap-10 min-h-screen py-20 lg:py-40 ${getBgColorClassForBeer(
            beer,
            undefined,
            true,
          )}`}
        >
          <H1 asH2 className="m-0 text-4xl lg:text-6xl xl:text-8xl">
            Sensory Profile
          </H1>
          <P className={getTextColorClassForBeer(beer, 'override:')}>{beer.sensoryDescription}</P>
          <Image
            src={getWheelTwoImgPathForBeer(beer)}
            alt={`Sensory profile of ${beer.name} beer`}
            className="w-full max-w-4xl"
          />
        </div>
        <div
          className={`py-20 lg:px-10 w-full min-h-screen flex justify-center items-center gap-10 flex-col-reverse lg:flex-row ${getBgColorClassForBeer(
            beer,
            undefined,
            true,
          )}`}
        >
          <div className="px-10 lg:px-0 w-full flex flex-col justify-center items-center gap-5 text-center">
            <H1 asH2 className={`m-0 ${getTextColorClassForBeer(beer, 'override:')}`}>
              A Cultural Gastronomic Experience
            </H1>
            <P className={getTextColorClassForBeer(beer, 'override:')}>
              Indulge in our recipes and gourmet food experiences.
            </P>
            <ButtonLink
              to="/food-pairing"
              main
              overrideBtnClassName={`${getTextColorClassForBeer(beer, 'override:')} ${getBorderColorClassForBeer(
                beer,
                'override:',
              )}`}
            >
              KNOW MORE
            </ButtonLink>
          </div>
          <Image
            src={getFoodPairingImgPathForBeer(beer)}
            alt={`Brewing process of ${beer.name} beer`}
            className="w-full"
            imgClassName="shadow-sm rounded-sm"
          />
        </div>
        <OtherBeers beer={beer} />
      </div>
    </Layout>
  );
};

export default BeerDetails;

export const query = graphql`
  query ($slug: String!, $language: String!) {
    beer: beers(slug: { eq: $slug }) {
      alc
      analyticalDescription
      artImg
      ibu
      ibuImg
      bottleImg
      bottleShadowImg
      color
      colorImg
      description
      introduction
      name
      pairingTeaser
      pouringImg
      slug
      sub
      titleImg
      awards {
        img
        name
        year
      }
      ingredients {
        alt
        img
      }
      sensoryDescription
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
