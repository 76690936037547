import React from 'react';
import { useOtherBeers } from '../../hooks';
import Image from '../images/image';
import CustomLink from '../link/customLink';
import { getBgColorClassForBeer, getBorderColorClassForBeer, getTextColorClassForBeer } from '../../utils';
import type { Beer } from '../../types';

interface OtherBeersProps {
  beer: Beer;
}

const OtherBeers: React.FC<OtherBeersProps> = ({ beer }) => {
  const otherBeers = useOtherBeers(beer);

  return (
    <section className="w-full">
      <div className="w-full flex justify-center items-center bg-primary text-white p-5 text-center">
        <h2 className="text-2xl lg:text-4xl">EXPERIENCE OTHER BREWS</h2>
      </div>
      <div className="mt-10 lg:mt-20 w-full flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-20">
        {otherBeers.map((otherBeer) => (
          <CustomLink
            key={otherBeer.slug}
            to={`/beers/${otherBeer.slug}`}
            className={`block relative transform hover:scale-105 p-2 xl:py-20 xl:px-32 lg:mx-0 w-11/12 lg:w-1/3 max-w-4xl border-2 ${getBorderColorClassForBeer(
              otherBeer,
            )} ${getBgColorClassForBeer(otherBeer, 'hover:', true)}`}
          >
            <div>
              <Image
                src={otherBeer.artImg}
                alt={`Bottle painting for ${otherBeer.name} beer`}
                className="hover:opacity-0 opacity-30 absolute top-0 left-0 right-0"
              />
              <div className="w-full flex justify-center items-start gap-2">
                <Image
                  src={otherBeer.bottleShadowImg}
                  alt={`${otherBeer.name} beer bottle with shadow`}
                  className="w-1/2 lg:w-1/2 mt-10"
                />
                <div
                  className={`w-2/3 lg:w-1/2 flex flex-col justify-center items-center gap-10 ${getTextColorClassForBeer(
                    otherBeer,
                  )}`}
                >
                  <h3 className="mt-10 text-4xl lg:text-6xl text-center">
                    <span className="font-bold">Loka</span>
                    <br />
                    <span className="font-semibold">{otherBeer.name}</span>
                  </h3>
                  <p className="text-xl lg:text-2xl font-semibold">{otherBeer.description}</p>
                  <p className="text-right self-end text-xl">{otherBeer.alc}</p>
                </div>
              </div>
            </div>
          </CustomLink>
        ))}
      </div>
    </section>
  );
};

export default OtherBeers;
